import { getAllMetrics } from '@/store/schemaBuilder/helpers';
import { SchemaMetricGroup } from '@/store/schemaBuilder/types';
import { z } from 'zod';

const schemaFormMetricsListValidation = z.array(z.any());
const schemaFormSubGroupValidation = z.object({
  metrics: schemaFormMetricsListValidation,
  displayName: z.string().min(1, 'Required'),
  enableTimePeriodFilter: z.boolean(),
})
  .refine((subGroup) => {
    if (subGroup.enableTimePeriodFilter === true && subGroup.metrics.length) {
      const numTimePeriodMetrics = subGroup.metrics.filter((metric) => metric.timePeriod).length;
      return numTimePeriodMetrics === subGroup.metrics.length;
    }
    return true;
  }, { message: 'All metrics must have an associated time period' })
  .refine((data) => data.metrics.length !== 0, { message: 'View must contain at least 1 metric' });

const schemaFormMetricGroupValidation = z.object({
  displayName: z.string().min(3, 'Must be at least 3 characters long'), // Add unique validation
  isRepeatingGroup: z.boolean(),
  calculatedTotals: z.boolean(),
  generalMetrics: schemaFormMetricsListValidation,
  subGroups: z.array(schemaFormSubGroupValidation),
}).refine((group) => {
  const { isRepeatingGroup } = group;
  const isReportingDateMetricInGroup = getAllMetrics([group]).some((metric) => metric.fin === 'reporting_date');
  return !(isRepeatingGroup && isReportingDateMetricInGroup);
}, { message: 'Reporting date metric can not exist within a multi-value group' });

const schemaFormValidationSchema = z.object({
  name: z.string().min(3, 'Must be at least 3 characters long'),
  groups: z.array(schemaFormMetricGroupValidation)
    .refine((groups) => {
      const groupContainingReportingDate = groups.find((metricGroup: SchemaMetricGroup) => {
        const metrics = getAllMetrics([metricGroup]);
        return metrics.some((metric) => metric.fin === 'reporting_date');
      });

      return groupContainingReportingDate;
    }, { message: 'Reporting date metric required in schema' }),
});

export default schemaFormValidationSchema;
